<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<template>
	<div class="app">
		<div class="content">
			<img class="img" alt="Grupo Babilonia logo" src="./assets/logo.png">
			<HelloWorld class="titulo"> </HelloWorld>
			<video controls class="square-video">
				<source src="/videos/babilonia_promo.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		<div>
			<h1> Podés apoyarnos con cualquier valor a través: </h1>
			<a href="https://link.mercadopago.com.uy/ccbabilonia">
				<img class="mercado-pago" alt="Mercado Pago" src="./assets/mercado_pago.png">
			</a>
			<h1> BROU CA: 110612774-00001 </h1>
			<h1> Poner en la descripcion: Casa Cultural Babilonia</h1>
		</div>

		</div>
	</div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    HelloWorld // Register the HelloWorld component
  }
};
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
	margin-top: 100px;
}

.mercado-pago{
	width: 30%;
	border-radius: 50%;
}

.content {
  /* Adjust margin for content if necessary */
  margin-top: 450px;
}

.square-video {
  width: 600px;
  height: 600px;
  object-fit: contain;
}

.titulo{
	font-weight: bold;
	font-size: xx-large;
	padding-bottom: 30px;
}

/* Mobile screens - small sizes */
@media (max-width: 480px) {
  .content {
    font-size: small;
  /*  margin-top: 300px; Reduce the margin */
  }
  .square-video {
    width: 50%; /* Set video width relative to screen size */
    height: auto; /* Adjust height automatically */
  }
	.titulo{
		font-size: x-large;
	}
	.img{
		width: 80%;
	}
}

/* For medium screens like tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .content {
    font-size: medium;
    margin-top: 300px; /* Adjust margin */
  }
  .square-video {
    width: 80%; /* Set a smaller width */
    height: auto; /* Height adjusts to width */
  }
}
</style>
